import { makeVar, ServerError, ServerParseError } from '@apollo/client';
import { AlertColor } from '@mui/material';
import { GraphQLError } from 'graphql';

interface SnackbarNotification {
  severity?: AlertColor;
  message?: string;
}
export const snackbarNotification = makeVar<SnackbarNotification>({});

type ReloadTarget = 'none' | 'contract';
export const reloadTarget = makeVar<ReloadTarget>('none');

type ApiErrorType =
  | GraphQLError
  | Error
  | ServerError
  | ServerParseError
  | undefined;
export const apiError = makeVar<ApiErrorType[]>([]);
// 認証エラーでサインアウトしたときの復帰用URL
export const urlForResume = makeVar<string>('');
export const mainContentLoading = makeVar<boolean>(false);

interface TopAlertMessageType {
  severity: 'success' | 'info' | 'warning' | 'error';
  message: string;
}
export const topAlertMessage = makeVar<TopAlertMessageType>({
  severity: 'success',
  message: '',
});
