import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Head from 'next/head';
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import '../styles/globals.css';
import { AppProps } from 'next/dist/shared/lib/router/router';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import apolloClient from '../lib/apollo/apolloClient';
import ApiErrorSnackbar from '../components/organisms/ApiErrorSnackbar';
import NotificationSnackbar from '../components/molecules/NotificationSnackbar';

const theme = createTheme({
  spacing: 4,
  typography: {
    fontFamily: [
      'Roboto',
      'YuGothic',
      '"Yu Gothic Medium"',
      '"Yu Gothic"',
      '"Helvetica Neue"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'HelveticaNeue',
      'Verdana',
      'Meiryo',
      'sans-serif',
    ].join(','),
    fontSize: 22.4, // 16px
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: '#332188',
      light: '#663BC0',
    },
    secondary: {
      main: '#0075C2',
    },
  },
});

const clientSideEmotionCache = createCache({ key: 'css' });

const MyApp = (props: AppProps) => {
  if (typeof window !== 'undefined') {
    const firebaseConfig = {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUKET,
      messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGE_SENDER_ID,
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
      measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    };
    try {
      initializeApp(firebaseConfig);
      const auth = getAuth();
      auth.useDeviceLanguage();
    } catch (error) {
      //
    }
  }

  const { Component, pageProps, emotionCache = clientSideEmotionCache } = props;

  return (
    <ApolloProvider client={apolloClient}>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1.0,minimum-scale=1.0"
          />
          <meta name="robots" content="noindex" />
        </Head>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Component {...pageProps} />

          <ApiErrorSnackbar />
          <NotificationSnackbar />
        </ThemeProvider>
      </CacheProvider>
    </ApolloProvider>
  );
};

export default MyApp;
