import { useReactiveVar } from '@apollo/client';
import { Snackbar, Slide, Alert } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { snackbarNotification } from '../../lib/apollo/globalVars';

const NotificationSnackbar = () => {
  const data = useReactiveVar(snackbarNotification);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (data.message && data.message) {
      setOpen(true);
    }
  }, [data]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={5000}
      // eslint-disable-next-line react/no-unstable-nested-components
      TransitionComponent={(prps) => <Slide {...prps} direction="up" />}
      open={open}
      onClose={(event, reason) => {
        if (reason === 'clickaway') return;
        setOpen(false);
        snackbarNotification({ severity: undefined, message: undefined });
      }}
    >
      <Alert severity={data.severity}>{data.message ?? ''}</Alert>
    </Snackbar>
  );
};

export default NotificationSnackbar;
