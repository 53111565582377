import {
  createHttpLink,
  ApolloClient,
  InMemoryCache,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { apiError } from './globalVars';

const httpLink = createHttpLink({
  uri: `${process.env.NEXT_PUBLIC_API_URL}`,
  credentials: 'include',
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
  const errors = [];
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      errors.push(err);
    });
  }

  if (networkError) {
    errors.push(networkError);
  }

  apiError(errors);
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, httpLink]),
});

export default apolloClient;
