import { useReactiveVar } from '@apollo/client';
import { Snackbar, Slide, Alert } from '@mui/material';
import { GraphQLError } from 'graphql';
import { useRouter } from 'next/router';
import React, { useState, useEffect, useCallback } from 'react';
import { apiError, urlForResume } from '../../lib/apollo/globalVars';

const ApiErrorSnackbar = () => {
  const router = useRouter();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const apiErr = useReactiveVar(apiError);
  const [errorMessage, setErrorMessage] = useState('通信エラーが発生しました');

  const onCloseSnackbar = useCallback(() => {
    apiError([]);
    setSnackbarOpen(false);
  }, []);

  useEffect(() => {
    // ホーム画面の場合は、エラーは出さない
    switch (router.pathname) {
      case '/':
      case '/signin':
      case '/signup':
      case '/password_reset':
        return;
      default:
        break;
    }

    setSnackbarOpen(apiErr.length > 0);

    const isUnauthorized = apiErr.some((error) => {
      const e = error as GraphQLError;
      return e?.extensions?.code === 'UNAUTHENTICATED';
    });
    if (isUnauthorized) {
      setErrorMessage('認証エラーが発生しました。再ログインしてください。');
      urlForResume(router.asPath);
      router.push('/signin');
      return;
    }

    const error = apiErr.find((err) => Boolean(err?.message));
    if (error) {
      setErrorMessage(error.message);
    }
  }, [apiErr, router]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
      // eslint-disable-next-line react/no-unstable-nested-components
      TransitionComponent={(prps) => <Slide {...prps} direction="down" />}
      open={snackbarOpen}
      onClose={onCloseSnackbar}
      sx={{
        pointerEvents: 'none',
      }}
    >
      <Alert severity="error" onClose={onCloseSnackbar}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

export default ApiErrorSnackbar;
